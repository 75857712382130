import React, { Component, useState } from "react";
import { Modal, Form, Col, Button } from 'react-bootstrap';
import classNames from 'classnames';
import validator from 'validator';
import _ from 'underscore';
import NumberFormat from 'react-number-format';
import Parser from 'html-react-parser';
import Loading from '../../components/Loading';

import PaxName from './PaxName';


export default class  RegisCovidTest extends Component {

    formDefaults = {
        title: { value: 'Mr', isValid: false, message: '' },
        email: { value: '', isValid: true, message: '' },
        familyName: { value: '', isValid: true, message: '' },
        givenName: { value: '', isValid: true, message: '' },
        phone: { value: '', isValid: true, message: '' },
        termsChecked : { value: false, isValid: false, message: '' },
        hospital: { value: '', isValid: false, message: '' },
        packages: { value: '', isValid: false, message: '' },
        paxs : { value: '', isValid: false, message: '' },
        type : { name : "", value : []}
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.formDefaults,
            genderOptions: ["Mr", "Mrs", "Ms"],
            hospitalOptions: [
                "โรงพยาบาลพญาไท 1/ Phyathai 1 Hospital", 
                "โรงพยาบาลพญาไท 2/ Phyathai 2 Hospital", 
                "โรงพยาบาลพญาไท 3/ Phyathai 3 Hospital",
                "โรงพยาบาลพญาไทนวมินทร์/ Phyathai Nawamin Hospital",
                "โรงพยาบาลพญาไทศรีราชา/ Phyathai Sriracha Hospital",
                "โรงพยาบาลเปาโล พหลโยธิน/ Paolo Phapolyothin",
                "โรงพยาบาลเปาโล เกษตร/ Paolo Kaset",
                "โรงพยาบาลเปาโลโชคชัย 4/ Paolo Chokchai 4",
                "โรงพยาบาลเปาโล รังสิต/ Paolo Rangsit",
                "โรงพยาบาลเปาโลพระประแดง/ Paolo Phrapradaeng",
                "โรงพยาบาลเปาโลสมุทรปราการ/ Paolo Samutprakarn"
            ],
            voucherOptions: [],
            errors: false,
            errorMessage: "",
            paxmessage : {
                title : [],
                familyName : [],
                givenName : []
            },
            amtOptions: {},
            purchase:[],
            totalVAT : 0,
            totalAmount: 0,
            serviceFee: 0,
            serviceFeeVAT: 0,
            disable : false,
            loading: true
        }

         this.handleCheckBox = this.handleCheckBox.bind(this);
         this.removePaxName = this.removePaxName.bind(this);
         this.onChange = this.onChange.bind(this);
         this.onPaxChange = this.onPaxChange.bind(this);
         this.onPackageChange = this.onPackageChange.bind(this);
         this.onChangeRoomNumber = this.onChangeRoomNumber.bind(this);
    }

    UNSAFE_componentWillMount(){
        const amtOptions = {};
        //const type = {};
        const packageOptions = [];
        var totalAmount = 0.00;
        _.each(this.props.data.product_type, function(val, key){
            amtOptions[val['code']] = {
                'name' : val['name'],
                'origin' : val['selling_price'],
                // 'vat': val['selling_price']/100*7,
                // 'amount': val['selling_price']*1.07
                'vat': 0,
                'amount': val['selling_price']
            };
            // type[val['code']] = {
            //     value: [], 
            //     isValid: true, 
            //     message: ''
            // };
            packageOptions.push({code: val['code'], name: val['name']});
            //totalAmount += parseFloat(val['selling_price']);
        })

        this.setState({
            email: { value: this.props.info.get('email') ? this.props.info.get('email') : '' , isValid: true, message: '' },
            title: { value: this.props.info.get('title') ? this.props.info.get('title') : 'Mr', isValid: false, message: '' },
            familyName: { value: this.props.info.get('lastname') ? this.props.info.get('lastname') : '', isValid: true, message: '' },
            givenName: { value: this.props.info.get('firstname') ? this.props.info.get('firstname') : '', isValid: true, message: '' },
            phone: { value: this.props.info.get('phone') ? "" + this.props.info.get('phone') : '', isValid: true, message: '' },
            voucherOptions : _.map(this.props.data.product_type, function(val, key){
                return val['code']
            }),
            amtOptions: amtOptions,
            //type : type,
            packageOptions : packageOptions,
            totalAmount : parseFloat(totalAmount).toFixed(2),
            loading:false
        })
    }

    handleCheckBox(e) {
        const newSelection = e.target.value;

        let newSelectionArray;
        if (this.state.vouchers.indexOf(newSelection) > -1) {
            newSelectionArray = this.state.vouchers.filter(
                s => s !== newSelection
            );
        } else {
            newSelectionArray = [...this.state.vouchers, newSelection];
        }

        this.setState(prevState => ({
                vouchers: newSelectionArray
            })
        );
    }


    removeAccents(str) {
        return str.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd').replace(/Đ/g, 'D');
    }

    handlePaxName(id, voucherType, familyName, givenName, title){

        let items = this.state.type.value;

        let item = {...items[id]};
        item = {
            familyName: this.removeAccents(familyName),
            givenName : this.removeAccents(givenName),
            title: title
        }

        items[id] = item;
        //console.log(id,items[id])
        this.setState(prevState => ({
                type: {
                    name : this.state.type.name,
                    value : items
                }
            })
        );

        //this.setState({[voucherType] : items});
    }

    removePaxName(id, voucherType){
        let items = this.state.type[voucherType].value;
        items.splice(id, 1);
        this.setState(prevState => ({
                [voucherType]: {
                    ...this.state.type[voucherType],
                    value : items
                }
            }),
            () => this.checkPurchase(voucherType, 0)
        );

    }

    onPaxChange = (e) => {
        e.preventDefault();
        if(e.target.value !== 0){
            if (this.state.hospital.value === ""){
                alert("Please select the hospital");
                return;
            }
            if (this.state.packages.value === ""){
                alert("Please select the package");
                return;
            }
        }

        if(e.target.value > 9){
            alert("Maximun Passengers");
            return;
        }

        let newSelectionArray = [];
        for(var i = 0; i < e.target.value; i++ ){
            if(this.state.type.value && this.state.type.value.hasOwnProperty(i)){
                newSelectionArray.push(this.state.type.value[i])
            }else{
                newSelectionArray.push({familyName : "", givenName : "", email : ""});
            }
            
        }

        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: this.removeAccents(e.target.value),
            },
            type: {
                name : this.state.packages.value,
                value: newSelectionArray
            }
        };

        let numPaxs = e.target.value;
        this.setState(state, () => this.checkPurchase(this.state.packages.value, numPaxs));

    }

    onPackageChange = (e) => {
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: this.removeAccents(e.target.value),
            },
            type : {
                name : e.target.value,
                value : this.state.type.value
            }
        };

        this.setState(state, () => this.state.packages.value !== "" ? this.checkPurchase(this.state.packages.value, this.state.paxs.value) : null);
    }

    addPaxName = (e) =>{
        e.preventDefault();

        const name = this.state.packages.value;
        let newSelectionArray = this.state.type[name].value;
        
        if(newSelectionArray.length < 5){
            newSelectionArray.push({familyName : "", givenName : "", email : ""});

            this.setState(prevState => ({
                    [name]: {
                        ...this.state.type,
                        value: newSelectionArray
                    }
                }),
                () => this.checkPurchase(name, 1)
            )
        }

    }

    checkPurchase(voucherType, num){
        this.setState({
            totalVAT: num * parseFloat(this.state.amtOptions[voucherType]['vat']).toFixed(2),
            totalAmount : num * parseFloat(this.state.amtOptions[voucherType]['amount']).toFixed(2)
        })
        // if(ty === 1){
        //     this.setState({
        //         totalVAT: parseFloat(parseFloat(this.state.totalVAT) +  parseFloat(this.state.amtOptions[voucherType]['vat'])).toFixed(2),
        //         totalAmount : parseFloat(parseFloat(this.state.totalAmount) +  parseFloat(this.state.amtOptions[voucherType]['amount'])).toFixed(2)
        //     })
        // }else {
        //     this.setState({
        //         totalVAT: parseFloat(parseFloat(this.state.totalVAT) -  parseFloat(this.state.amtOptions[voucherType]['vat'])).toFixed(2),
        //         totalAmount : parseFloat(parseFloat(this.state.totalAmount) -  parseFloat(this.state.amtOptions[voucherType]['amount'])).toFixed(2)
        //     })
        // }

    }


    onChange = (e) => {
       
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: this.removeAccents(e.target.value),
                isValid : e.target.value !== "" ? true : false
            }
        };

        this.setState(state);

    }

    checkPurchaseRoom(){
        var that = this;
        let totalVAT = 0;
        let totalAmount = 0;
        _.each(this.state.type, function(room, key){
            totalVAT += that.state.amtOptions[key]['vat'] * room.value;
            totalAmount += that.state.amtOptions[key]['amount'] * room.value;
        })

        this.setState({
            totalVAT: parseFloat(totalVAT).toFixed(2),
            totalAmount : parseFloat(totalAmount).toFixed(2)
        })

        // this.setState({
        //     totalVAT: parseFloat(parseFloat(this.state.totalVAT) +  parseFloat(this.state.amtOptions[voucherType]['vat']) * ty).toFixed(2),
        //     totalAmount : parseFloat(parseFloat(this.state.totalAmount) +  parseFloat(this.state.amtOptions[voucherType]['amount']) * ty).toFixed(2)
        // })

    }

    onChangeRoomNumber = (e) => {

        e.preventDefault();
        let name = e.target.name;
        let rooms = {...this.state.type};
        let room = {...rooms[name]};
        room.value = parseInt(e.target.value);
        rooms[name] = room;
        this.setState({type : rooms},() => this.checkPurchaseRoom());
        // const state = {
        //     ...this.state,
        //     [e.target.name]: {
        //         ...this.state[e.target.name],
        //         value: this.removeAccents(e.target.value),
        //     }
        // };

        // this.setState(state);

    }

    onSubmit = (e) => {
        e.preventDefault();
        this.resetValidationStates();
        
        if (this.formIsValid()) {
            this.setState({
                //disable: true,
            })
            const { title, familyName, givenName, email, phone , type, totalAmount, totalVAT, hospital, packages, paxs, paxmessage } = this.state;

            let userData = {
                contact:{
                    title: title.value === null ? "Mr" :title.value ,
                    email: email.value.trim(),
                    familyName : familyName.value.trim().toUpperCase(),
                    givenName : givenName.value.trim().toUpperCase(),
                    phone: phone.value.trim().toUpperCase()
                },
                vouchers :[],
                g : "",
                h : "",
                paymentVAT : parseFloat(parseFloat(totalVAT).toFixed(2)),
                paymentTotal: parseFloat(parseFloat(totalAmount).toFixed(2))
            };

            userData.vouchers.push({
                name: type.name,
                paxs: type.value
            })

            if(this.props.info.get('g')){
                userData.g = this.props.info.get('g');
                userData.h = this.props.info.get('h');
            }

            userData.code = this.props.data.code;
            userData.hospital = this.state.hospital.value;
            userData.package = this.state.packages.value;

            var requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify(userData),
            };
            
            fetch( this.props.apiUrl + "/api/voucher/covidTest/buy", requestOptions)
                .then(response => response.json())
                .then(result => {

                    const data = result;//JSON.parse(result);
                    this.setState({
                        disable: false,
                    })
                    
                    if(data === ""){
                        this.setState({
                            errors : true,
                            errorMessage: "Oop! Something wrong. Please check your information and try again"
                        })
                        alert("Oop! Something wrong. Please check your information and try again");

                    }else if(!data.status){
                        this.setState({
                            disable: false,
                            errors : true,
                            errorMessage: data.message
                        })
                        alert(data.message);
                    }else {
                        window.location.href = data.url;
                    }

                })
                .catch(error => console.log('error', error));

        }
    }

    formIsValid = () => {

        const title = { ...this.state.title };
        const email = { ...this.state.email };
        const familyName = { ...this.state.familyName };
        const givenName = { ...this.state.givenName };
        const phone = { ...this.state.phone };
        const termsChecked = { ...this.state.termsChecked };
        const type = { ...this.state.type };
        const packages = { ...this.state.packages };
        const hospital = { ...this.state.hospital };
        const paxs = { ...this.state.paxs };
        var errors = { ...this.state.errors };
        var errorMessage = {...this.state.errorMessage};
        const paxmessage = {...this.state.paxmessage};
        let isGood = true;

        email.message = '';
        familyName.message = '';
        givenName.message = '';
        phone.message = ''
        paxmessage.title = [];
        paxmessage.familyName = [];
        paxmessage.givenName = [];

        if (!validator.isEmail(email.value)) {
            email.isValid = false;
            email.message = 'Not a valid email address';
            isGood = false;
        }

        if (validator.isEmpty(familyName.value) || !validator.matches(familyName.value.trim(),'^[a-zA-Z]+$')){
            familyName.isValid = false;
            familyName.message = 'The family name is not correct. It accepts only characters.';
            isGood = false;
        }


        if (validator.isEmpty(givenName.value) || !validator.matches(givenName.value.trim(),'^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$')){
            givenName.isValid = false;
            givenName.message = 'The given name is not correct. It accepts only characters and single space.';
            isGood = false;
        }


        if (!validator.isMobilePhone(phone.value,"any", true)){
            phone.isValid = false;
            phone.message = 'Not a valid phone number (eg. +66xxxxxxx)';
            isGood = false;
        }

        if (!termsChecked.value){
            termsChecked.isValid = false;
            isGood = false;
            errors = true;
            alert("You must accept the terms and conditions");
            this.setState({termsChecked:{
                ...this.state.termsChecked,
                message : 'You must accept the terms and conditions'
            }})
            
        }

        if (!hospital.value){
            hospital.isValid = false;
            hospital.message = 'Please select hospital';
            isGood = false;
        }

        if (!packages.value){
            hospital.isValid = false;
            hospital.message = 'Please select package';
            isGood = false;
        }

        if (!paxs.value){
            hospital.isValid = false;
            hospital.message = 'Please select number of Pax';
            isGood = false;
        }

        var a = true;

        //const t = _.find(type, function(num, key){ return num.value > 0; })
        if(type.value.length <= 0){
            isGood = false;
            errors = true;
            alert("Please choose at least 1 voucher");
            errorMessage = "";
        }

        if(type.value.length > 0){
            for(var i = 0; i < type.value.length; i++){
                let paxInfo = type.value[i];
                
                // if (validator.isEmpty(paxInfo.title)) {
                //     paxmessage.title.push({
                //         key : i,
                //         message : 'Please select title'
                //     });
                //     isGood = false;
                // }
        
                if (validator.isEmpty(paxInfo.familyName) || !validator.matches(paxInfo.familyName.trim(),'^[a-zA-Z]+$')){
                    paxmessage.familyName.push({
                        key : i,
                        message: 'The family name is not correct. It accepts only characters.'
                    });
                    isGood = false;
                }
        
                if (validator.isEmpty(paxInfo.givenName) || !validator.matches(paxInfo.givenName.trim(),'^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$')){
                    paxmessage.givenName.push({
                        key : i,
                        message: 'The family name is not correct. It accepts only characters and single space.'
                    });
                    isGood = false;
                }
            }
           
        }
        
        if (!isGood) {
            this.setState({
                email,
                title,
                phone,
                familyName,
                givenName,
                errors,
                errorMessage,
                paxmessage
            });

            
        }

        return isGood;
    }

    resetValidationStates = () => {
        const state = JSON.parse(JSON.stringify(this.state));

        Object.keys(state).map(key => {
            if (state[key].hasOwnProperty('isValid')) {
                state[key].isValid = true;
                state[key].message = '';
            }

        });

        this.setState(state);
        this.setState({errors: false, errorMessage: ""})


    }

    resetForm = () => {
        this.setState(...this.formDefaults);
    }


     render(){

         const {language, languages} = this.props;

         const {type, title, email, termsChecked, familyName, givenName, phone, errors, errorMessage, hospital, packages, paxs, paxmessage} = this.state;

         const emailGroupClass = classNames('form-group',
             { 'has-error': !email.isValid }
         );
         const titleGroupClass = classNames('form-group',
             { 'has-error': !title.isValid }
         );
         const famailyNameGroupClass = classNames('form-group',
             { 'has-error': !familyName.isValid }
         );
         const givenNameGroupClass = classNames('form-group',
             { 'has-error': !givenName.isValid }
         );
         const phoneGroupClass = classNames('form-group',
             { 'has-error': !phone.isValid }
         );
        //  const termsCheckedGroupClass = classNames('form-group',
        //      { 'has-error': !termsChecked.isValid }
        //  );
        //  const typeAGroupClass = classNames('card-body',
        //      { 'has-error': !typeA.isValid }
        //  );

        //  const typeBGroupClass = classNames('card-body',
        //      { 'has-error': !typeB.isValid }
        //  );

        //  const Sky1000GroupClass = classNames('card-body',
        //      { 'has-error': !Sky1000.isValid }
        //  );

         return (
            <section className="join-us" id="join-us">
                {!this.state.loading ? (
                <div className="contact-us-bx clearfix">
                    <div className="">
                        <Form autoComplete="off" className="form-contact" id="join-us" onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h4 className="title-contact">
                                        {languages[language]['Regis.label']}
                                    </h4>
                                </div>

                                <div className="col-sm-12">
                                    <div className="inner-form-contact">
                                        <span>{languages[language]['Regis.fill']}:</span>
                                        <div className="main-form" style={{"marginTop": "15px"}}>
                                            <div className="top-form">
                                                <div className="row" >

                                                    <Form.Group className={titleGroupClass} as={Col} md="2" xs="12" controlId={"title"}>
                                                        <Form.Label>{languages[language]['Regis.title']}</Form.Label>
                                                        <Form.Control as="select"
                                                                      required
                                                                      value={this.state.title.value}
                                                                      onChange={this.onChange}
                                                                      name="title"
                                                        >
                                                            <option value="" label={languages[language]['Regis.title']}>
                                                            Select Title
                                                            </option>
                                                            {this.state.genderOptions.map(option => {
                                                                return (
                                                                    <option key={option} value={option} label={option}>
                                                                        {option}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">{email.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className={famailyNameGroupClass} as={Col} md="2" xs="12" controlId="familyName">
                                                        <Form.Label>{languages[language]['Regis.family']}</Form.Label>
                                                        <Form.Control
                                                            name="familyName"
                                                            value={this.state.familyName.value}
                                                            required
                                                            type="text"
                                                            placeholder="Enter your name"
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{familyName.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className={givenNameGroupClass} as={Col} md="3" xs="12" controlId="givenName">
                                                        <Form.Label>{languages[language]['Regis.given']}</Form.Label>
                                                        <Form.Control
                                                            name="givenName"
                                                            required
                                                            type="text"
                                                            placeholder="Enter your middle and given name"
                                                            value={this.state.givenName.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{givenName.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className={emailGroupClass} as={Col} md="3" xs="12" controlId="email">
                                                        <Form.Label>{languages[language]['Regis.email']}</Form.Label>
                                                        <Form.Control
                                                            name="email"
                                                            required
                                                            type="email"
                                                            placeholder="Enter your email"
                                                            value={this.state.email.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{email.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className={phoneGroupClass} as={Col} md="2" xs="12" controlId="phone">
                                                        <Form.Label>{languages[language]['Regis.phone']}</Form.Label>
                                                        <Form.Control
                                                            name="phone"
                                                            required
                                                            type="text"
                                                            placeholder="eg. +665555551212" 
                                                            //pattern="(\+|(\+[1-9])?[0-9]*)"
                                                            value={this.state.phone.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{phone.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                            </div>
                                            </div>

                                            <div className="top-form" style={{marginTop:"20px", borderRadius: "40px"}}>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                    <Form.Group className={titleGroupClass} as={Col} md="12" xs="12" controlId={"hospital"}>
                                                        <Form.Label>{languages[language]['CovidTest']['selectHospital']}</Form.Label>
                                                        <Form.Control as="select"
                                                                      required
                                                                      value={this.state.hospital.value}
                                                                      onChange={this.onChange}
                                                                      name="hospital"
                                                        >
                                                            <option value="" label={languages[language]['CovidTest']['selectHospital']}>
                                                                {languages[language]['CovidTest']['selectHospital']}
                                                            </option>
                                                            {this.state.hospitalOptions.map(option => {
                                                                return (
                                                                    <option key={option} value={option} label={option}>
                                                                        {option}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">{hospital.message}</Form.Control.Feedback>
                                                    </Form.Group>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                    <Form.Group className={titleGroupClass} as={Col} md="6" xs="12" controlId={"packages"}>
                                                        <Form.Label>{languages[language]['CovidTest']['selectPackages']}</Form.Label>
                                                        <Form.Control as="select"
                                                                      required
                                                                      value={this.state.packages.value}
                                                                      onChange={this.onPackageChange}
                                                                      name="packages"
                                                        >
                                                            <option value="" label={languages[language]['CovidTest']['selectPackages']}>
                                                                {languages[language]['CovidTest']['selectPackages']}
                                                            </option>
                                                            {this.state.packageOptions.map(option => {
                                                                return (
                                                                    <option key={option['code']} value={option['code']} label={option['name']}>
                                                                        {option['name']}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">{packages.message}</Form.Control.Feedback>
                                                    </Form.Group>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="bottom-form">
                                                <div className="row">
                                                    <div className="col-md-6 col-xs-12">
                                                        <div className="card">
                                                            <div className="card-body" style={{borderRadius: "30px"}}>
                                                                <div className="row">
                                                                        <div className="col-12">
                                                                            <h3 style={{color: "red"}}>How many pax?</h3>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <p>{languages[language]['CovidTest']['paxs']['select']}</p>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <Form.Group controlId={"paxs"}>
                                                                                    <Form.Control as="select"
                                                                                                required
                                                                                                value={paxs.value}
                                                                                                onChange={this.onPaxChange}
                                                                                                name="paxs"
                                                                                    >
                                                                                        {[0,1,2,3,4,5,6,7,8,9].map(option => {
                                                                                            return (
                                                                                                <option key={option} value={option} label={option}>
                                                                                                    {option}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </Form.Control>
                                                                                    <Form.Control.Feedback type="invalid">{paxs.message}</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                        </div>
                                                                        <div className="col-12">{languages[language]['CovidTest']['paxs']['allguest']}
                                                                        </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                {this.state.type.name ? 
                                                
                                                    this.state.type.value.map((option, index)  => {
                                                        return (
                                                            <PaxName data={option} errormessage={{...paxmessage}} contactName={{familyName, givenName, title}} language={language} languages={languages}  key={index} id={index} voucherType={this.state.type.name} handlePaxName={this.handlePaxName.bind(this)} handleDelPaxName={this.removePaxName.bind(this)} />
                                                        );
                                                    })
                                                :
                                                    <div></div>

                                                }
                                                
                                            </div>

                                            <div className="bottom-form">
                                                
                                                <div className="row">
                                                    
                                                    <div className="col-md-6 col-xs-12">
                                                        <div className="container purchase">
                                                            <div className="row">
                                                                <div className="col"><h4>{languages[language]['Regis.purchase']}</h4></div>
                                                            </div>
                                                            { this.state.type.name !== '' ?
                                                                <div className="mb-2 row">
                                                                    <div className="col">{this.state.amtOptions[this.state.type.name].name} X {this.state.type.value.length}</div>
                                                                    <div className="text-right col">
                                                                        <NumberFormat value={parseFloat(this.state.amtOptions[this.state.type.name].origin * this.state.type.value.length).toFixed(2)} displayType={'text'} thousandSeparator={true} /> THB</div>
                                                                </div>
                                                                // this.state.type.value.map((key , i) => {
                                                                //     return (
                                                                        
                                                                //         <div key={i} >
                                                                //         {type[key].value === 0 ? 
                                                                //         <div className="mb-2 row" >
                                                                //             <div className="col">{this.state.amtOptions[key].name} X 0</div>
                                                                //             <div className="text-right col">0.00 THB</div>
                                                                //         </div>
                                                                //         :
                                                                //         <div className="mb-2 row">
                                                                //                 <div className="col">{this.state.amtOptions[key].name} X {type[key].value}</div>
                                                                //                 <div className="text-right col"><NumberFormat value={parseFloat(this.state.amtOptions[key].origin * type[key].value).toFixed(2)} displayType={'text'} thousandSeparator={true} /> THB</div>
                                                                //             </div>
                                                                //         }
                                                                //         </div>
                                                                        
                                                                //     )
                                                                // })}
                                                            :
                                                            <div></div>
                                                            }

                                                            <div className="totalRow row">
                                                                <div className="col">{languages[language]['Regis.total']}</div>
                                                                <div className="text-right col"><NumberFormat value={parseFloat(this.state.totalAmount).toFixed(2)} displayType={'text'} thousandSeparator={true}  /> THB</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                                    <div className="form-group has-error form-group">
                                                                            <div className="form-check">
                                                                                <input required type="checkbox" className="form-check-input" onClick={e => this.setState({termsChecked : { ...this.state.termsChecked,value : e.target.checked}})}/>
                                                                                <Termsandconditions terms={language === 'th' ? this.props.data.product_type[0].terms_th : this.props.data.product_type[0].terms} lang={languages[language]['CovidTest']['acceptT&C']}/>
                                                                                <label title="" className="form-check-label" style={{color:"black"}}>{languages[language]['CovidTest']['readT&C']}</label>
                                                                            </div>
                                                                        <div className="valid-feedback" >{termsChecked.message}</div>
                                                                    </div>
                                                                    
                                                                </div>
                                                        {/* <p><i>{languages[language]['points']}</i></p> */}
                                                        <button className="button next" type="submit" disabled={this.state.disable}>Submit</button>
                                                    </div>

                                                </div>
                                                
                                            </div>
                                            
                                </div>
                            </div>


                        </div>

                    </div>
                        </Form>

                        <p>{errors === true ? <span>{errorMessage}</span>: ""}</p>
                     </div>
                </div>
                ) : <Loading /> }
            
            </section>
        )
     }

}


function Termsandconditions(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <a href="#" variant="primary" onClick={handleShow}>
          {props.lang}
        </a>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{backgroundColor:"red"}}>
            <Modal.Title>Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {Parser(props.terms)}
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
  }

