import React, { Component } from "react";
//import Loading from '../../components/Loading';
import MiddlePowerPackFamilyDom from './middle';
import RegisPowerPackFamilyDom from './regis';

export default class PowerPackFamilyDom extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
          data : null,
          loading: true
        };
    }

    render() {
        var info = new URLSearchParams(localStorage.getItem('info'));
        return (
            <div className="clearfix wrap-home" data-seuence="500">
                <div className="fallingLeaves"></div>
                <section className="top-section text-center">
                    <div >
                        <img alt="Power Pack Family" src="/images/power-pack-family-dom/banner.jpg" className="d-none d-sm-block w-100" style={{width:'100%'}} />
                        <img alt="Power Pack Family" src="/images/power-pack-family-dom/banner-mobile.jpg" className="d-block d-sm-none w-100" style={{width:'100%'}} />
                    </div>
                </section>
                <MiddlePowerPackFamilyDom {...this.props} />
                <RegisPowerPackFamilyDom info={info} {...this.props} />
            </div>
        )
    }
}
