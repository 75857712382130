import React from 'react';
import { ListGroup } from 'react-bootstrap';

const mar = {
    marginLeft:'20px'
}

function FAQ(props) {
    const {language, languages} = props;

    return (
        <div className="terms" style={{marginTop : '2%'}}>
            <div id="wrap" className="container">
                <main id="main">
                    <section id="main__content" className="main__content section__2">
                        <div className="wrap__content">

                            <div className="div__section">
                                <div className="container">
                                    <div className="row">
                                        <h4 style={{margin:"auto"}} >{languages[language]['Pass']['faq']['passtitle']}</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <ListGroup>
                                                {languages[language]['Pass']['faq']['pass'].map((key, i) => {
                                                    return (
                                                        <ListGroup.Item key={i} >{key}</ListGroup.Item>
                                                    );
                                                }
                                                )}
                                            </ListGroup>
                                        
                                            
                                        </div>
                                    </div>
                                    <br/>
                                </div>

                            </div>
                        </div>
                    </section>
                </main>


            </div>
        </div>
    );
}

export default FAQ;
