import React,{Component, useState} from "react";
import { Carousel, Modal, Button, Tabs, Tab } from 'react-bootstrap';
import Parser from 'html-react-parser';
import Loading from '../../components/Loading';
import NumberFormat from 'react-number-format';
import ReadMoreReact from 'read-more-react';

export default class MiddleCovidTest extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          data : null,
          loading: true
        };
    }

    UNSAFE_componentWillMount() {
        this.setState({ data : this.props.data, loading:false })
    }

    render(){
        const {language, languages} = this.props;
        const colorText = {
            color: "white"
        }

        const boxCSS = {
            backgroundColor : "White",
            borderRadius : "15px",
            margin : 0,
            marginBottom : "20px"

        }

        const boxHeaderCSS = {
            borderTopRightRadius : "15px",
            borderTopLeftRadius : "15px",
            backgroundColor:'red', 
            padding:'10px'
        }

        return (
            <div className="main-background">

                <section className="top-middle week-gold" style={{paddingBottom: "10%"}}>
                    <div className="inner-week-gold">
                        {!this.state.loading ? (
                        <div className="container">
                            {this.state.data.map(voucher => {
                            return (
                                <div key={voucher['id']} > 
                                    
                                    <div className="row" style={boxCSS}>
                                        <div className="col-12" style={{marginBottom : "20px"}}>
                                            <Description description={ language === 'th' ? voucher['description_th'] : voucher['description']}  />
                                            <br/>
                                            <img src="/images/covidtest/info.jpg" style={{borderRadius : "30px", width : "100%"}} alt="covid-test" />
                                        </div>
                                    </div>
                                    <div className="row" style={boxCSS}>
                                        <div className="col-12" style={boxHeaderCSS}>
                                            <h3 style={colorText}>Package</h3>
                                        </div> 
                                        {voucher['product_type'].map((product, i) => {
                                            return (
                                                <div key={product['id']} className="col-12" style={{paddingBottom:"20px"}}>
                                                    <div className="row" style={{paddingTop:"20px",paddingBottom:"20px", width:"100%", borderBottom: "1px solid gray"}}>
                                                        <div className="col-md-5 col-12">
                                                            <img src={"/images/covidtest/" + (i === 0 ? "pcr" : "antigen" ) + ".png"} alt="Covid-19 Test" style={{width:"100%"}}/>
                                                            <br/>
                                                        </div>
                                            
                                                        <div className="col-md-7 col-12" style={{paddingTop:"20px"}}>
                                                            <h3 style={{color:"red"}}>{language === 'th' ? product['name_th'] : product['name']}</h3>
                                                            <span>{language === 'th' ? product['validity_th'] : product['validity']}</span>
                                                            <br/>
                                                            <h3 style={{color:"red"}}>{product['selling_price'] + " " + languages[language]['CovidTest']['priceType']}</h3>
                                                            <br/>
                                                            <a href="#join-us" className="buttonbasic page-scroll">BUY NOW</a>
                                                        </div>
                                                        <br/>
                                                    </div>

                                                </div>
                                            )
                                            

                                        })}

                                    </div>
                                    
                                </div>
                            
                            )
                                        
                            })}
                        </div>
                        ) : <Loading />
                        }
                    </div>
                    
                    <span className="img-hill"><img alt="" src="/images/top_cloud_2.png" className="img-responsive" /></span>
                </section>
            
            </div>
        )
    }
}

function Termsandconditions(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <a href="#" variant="primary" onClick={handleShow}>
          Terms & Conditions
        </a>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{backgroundColor:"red"}}>
            <Modal.Title>Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {Parser(props.terms)}
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
  }

function Description(props){
    const colorText = {
        color: "#4D4D4D",
        fontSize: "1em"
    }
    console.log(props.description);
    if(props.description !== null){
        let t = props.description.split("<br/>");
        return (
            <>
                <div>
                <br/>
                <strong style={colorText}>{Parser(t[0])}</strong>
                <ReadMoreReact 
                                min={100}
                                ideal={200}
                                text={t[1]} 
                                readMoreText="...read more"
                                />
                </div>
            </>
        )
    }else{
        return (
            <div></div>
        )
    }
    
    
}
