import React, { Component } from "react";
import Loading from '../../components/Loading';
import MiddleCovidTest from './middle';
import RegisCovidTest from './regis';

export default class CovidTest extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
          data : [],
          loading: true
        };
    }

    UNSAFE_componentWillMount() {
        fetch(this.props.apiUrl + '/api/voucher/shop/21')
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ data : responseJson, loading:false })
            })
            .catch((error) => {
              console.error(error);
            });
    }

    render() {
        const info = new URLSearchParams(localStorage.getItem('info'));
        return (
            <div className="clearfix wrap-home" data-seuence="500">
                <div className="fallingLeaves"></div>
                <section className="top-section text-center">
                    <div >
                        <img alt="..." src="/images/covidtest/banner.jpg" className="d-none d-sm-block w-100" style={{width:'100%'}} />
                        <img alt="..." src="/images/covidtest/banner-mobile.jpg" className="d-block d-sm-none w-100" style={{width:'100%'}} />
                    </div>
                </section>
                {!this.state.loading ? (
                    <div>
                        <MiddleCovidTest {...this.props} data={this.state.data} />
                        <RegisCovidTest info={info} {...this.props} data={this.state.data[0]} />
                    </div>
                ): <Loading />
                }
                
            </div>
        )
    }
}