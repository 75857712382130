import React, { Component } from "react";

import {Form, Col} from "react-bootstrap";
//import Input from "../components/Input";
import classNames from 'classnames';
import _ from 'underscore';

const btn = {
    float : 'right',
    marginRight: '2px'
}

export default class  PaxName extends Component {

    constructor(props) {
        super(props);
        this.state = {
            familyName : "",
            givenName : "",
            title : "",
            isChecked : false,
            genderOptions: ["Mr", "Mrs", "Ms"],
        }

        this.handleFamilyname = this.handleFamilyname.bind(this);
        this.handleGivenname = this.handleGivenname.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.handleVouchers = this.handleVouchers.bind(this);
        this.useContactName = this.useContactName.bind(this);
    }

    componentDidMount(){
       // console.log(this.props.contactName)

    }

    componentWillMount() {

        // if(this.props.contactName){
        //     this.setState(
        //         prevState => ({
        //             familyName: this.props.contactName.familyName.value,
        //             givenName : this.props.contactName.givenName.value,
        //             email: this.props.contactName.email.value
        //         })
        //     );
        // }
    }

    componentDidUpdate(prevProps){
        if(this.state.isChecked){

            // this.props.handlePaxName(
            //     this.props.id,this.props.voucherType,
            //     this.props.contactName.familyName.value ,
            //     this.props.contactName.givenName.value,
            //     this.props.contactName.email.value)

            // this.setState(
            //     prevState => ({
            //         familyName: this.props.contactName.familyName.value,
            //         givenName : this.props.contactName.givenName.value,
            //         email: this.props.contactName.email.value
            //     })
            // );
        }
    }

    componentWillReceiveProps(prevProps){
        //console.log(this.props.data)
        //if(this.props.data !== prevProps.data){
        //     this.setState(
        //         {
        //             familyName: this.props.data.familyName,
        //             givenName : this.props.data.givenName,
        //             email: this.props.data.email
        //         }
        //     );
        //}
    }


    handleFamilyname(e){
        let value = e.target.value;
        //value = value.toUpperCase();
        this.props.handlePaxName(this.props.id,this.props.voucherType, value, this.props.data.givenName, this.props.data.title)
        // this.setState(
        //     prevState => ({
        //         familyName: value
        //     }),
        //     () =>
        // );



    }

    handleGivenname(e){
        let value = e.target.value;
        //value = value.toUpperCase();
        //this.props.handlePaxName(this.props.id,this.props.voucherType, this.state.familyName, value)
        this.props.handlePaxName(this.props.id,this.props.voucherType, this.props.data.familyName, value, this.props.data.title)
        // this.setState(
        //     prevState => ({
        //         givenName: value
        //     }),
        //     ()=>
        // );


    }

    handleTitle(e){
        let value = e.target.value;
        //value = value.toUpperCase();
        //this.props.handlePaxName(this.props.id,this.props.voucherType, this.state.familyName, value)
        this.props.handlePaxName(this.props.id,this.props.voucherType, this.props.data.familyName, this.props.data.givenName, value)
        // this.setState(
        //     prevState => ({
        //         email: value
        //     }),
        //     ()=> this.props.handlePaxName(this.props.id,this.props.voucherType, this.state.familyName, this.state.givenName, value)
        // );
    }

    handleVouchers(e){
        e.preventDefault();
        //console.log(this.props.id,this.props.voucherType)
        this.props.handleDelPaxName(this.props.id,this.props.voucherType);
    }

    useContactName(e){
        const isChecked = e.target.checked;

        this.setState(prevState => ({
            isChecked: isChecked

        }));


        if(isChecked){
            this.props.handlePaxName(this.props.id,this.props.voucherType, this.props.contactName.familyName.value , this.props.contactName.givenName.value, this.props.contactName.title.value)

            // this.setState(prevState => ({
            //         familyName : this.props.contactName.familyName.value,
            //         givenName :  this.props.contactName.givenName.value,
            //         email : this.props.contactName.email.value
            //     }),
            //     ()=> this.props.handlePaxName(this.props.id,this.props.voucherType, this.props.contactName.familyName.value , this.props.contactName.givenName.value, this.props.contactName.email.value)
            // );
        }else {
            this.props.handlePaxName(this.props.id,this.props.voucherType, "", "", "")
            // this.setState(prevState => ({
            //         familyName : "",
            //         givenName :  "",
            //         email:""
            //     }),
            //     ()=> this.props.handlePaxName(this.props.id,this.props.voucherType, "", "", "")
            // );
        }


        // if(isChecked){
        //     this.setState(prevState => ({
        //         familyName : this.props.contactName.familyName.value,
        //         givenName :  this.props.contactName.givenName.value,
        //         email : this.props.contactName.email.value
        //     }),
        //         ()=> this.props.handlePaxName(this.props.id,this.props.voucherType, this.props.contactName.familyName.value , this.props.contactName.givenName.value, this.props.contactName.email.value)
        //     );
        // }else {
        //     this.setState(prevState => ({
        //         familyName : "",
        //         givenName :  "",
        //         email:""
        //     }),
        //         ()=> this.props.handlePaxName(this.props.id,this.props.voucherType, "", "", "")
        //     );
        // }



    }

    render(){

        const {language,languages, errormessage, id} = this.props;
       
        
        var titleGroupClass = classNames('form-group',
             { 'has-error': false }
         );
         var titleerror = '';

        var famailyNameGroupClass = classNames('form-group',
            { 'has-error':  false }
        );
        var familyerror = '';
        var givenNameGroupClass = classNames('form-group',
            { 'has-error':  false}
        );
        var givenerror = '';

        if(errormessage.title.length > 0){
            const title = _.filter(errormessage.title, function (num) {
                return (
                    num.key === id
                )
            });
            if(title.length > 0){
                titleGroupClass = classNames('form-group',
                    { 'has-error': true }
                );
                titleerror = title[0].message;
            }
        }

        if(errormessage.familyName.length > 0){
            const familyName = _.filter(errormessage.familyName, function (num) {
                return (
                    num.key === id
                )
            });
            if(familyName.length > 0){
                famailyNameGroupClass = classNames('form-group',
                    { 'has-error': true }
                );
                familyerror = familyName[0].message;
            }
        }

        if(errormessage.givenName.length > 0){
            const givenName = _.filter(errormessage.givenName, function (num) {
                return (
                    num.key === id
                )
            });
            if(givenName.length > 0){
                givenNameGroupClass = classNames('form-group',
                    { 'has-error': true }
                );
                givenerror = givenName[0].message;
            }
        }
        
        // const famailyNameGroupClass = classNames('form-group',
        //  { 'has-error':  errormessage.familyName.hasOwnProperty(id) }
        // );
        // const givenNameGroupClass = classNames('form-group',
        //     { 'has-error':  errormessage.givenName.hasOwnProperty(id)}
        // );
        return (
            <div className="row" style={{backgroundColor: "red", borderRadius : "20px", color : "white"}}>
                {/* <div className="row col-12">
                    <div className="col-md-10 col-xs-10 col-sm-10 col-10">
                        <div className="item-choose-service">
                            <label className="lb-choose-service">
                                <input type="checkbox" name="service" value="1" defaultChecked={this.state.isChecked} onClick={this.useContactName}/>
                                <span className="text-choose">{languages[language]['Regis.usePrimary']}</span>
                            </label>
                        </div>
                    </div>
                </div> */}
                <div className="col-12">
                    <h2>Voucher {this.props.id + 1}</h2>
                </div>
                <Form.Group className={titleGroupClass} as={Col} md="2" xs="12" controlId={this.props.voucherType+"-title-"+ this.props.id}>
                    <Form.Label>{languages[language]['Regis.title']}</Form.Label>
                        <Form.Control as="select"
                            required
                            value={this.props.data.title}
                            onChange={this.handleTitle}
                            name={this.props.voucherType+"-title-"+ this.props.id}
                        >
                            <option value="" label={languages[language]['Regis.title']}>
                            Select Title
                            </option>
                            {this.state.genderOptions.map(option => {
                                return (
                                    <option key={option} value={option} label={option}>
                                        {option}
                                    </option>
                                );
                            })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{titleerror}</Form.Control.Feedback>
                        </Form.Group>

                <Form.Group as={Col} md="5" xs="12" className={famailyNameGroupClass} controlId={this.props.voucherType+"-familyName-"+ this.props.id}>
                    <Form.Label >{languages[language]['Regis.family']}*</Form.Label>
                    <Form.Control
                        name={this.props.voucherType+"-familyName-"+ this.props.id}
                        value={this.props.data.familyName}
                        required
                        type="text"
                        placeholder="Owner's Family Name*"
                        onChange={this.handleFamilyname}
                        autoComplete="off"
                    />  
                    <Form.Control.Feedback>{familyerror}</Form.Control.Feedback>
                    {/* errormessage.familyName.hasOwnProperty(id)?errormessage.familyName[id]:'' */}
                </Form.Group>


                <Form.Group as={Col} md="5" xs="12" className={givenNameGroupClass} controlId={this.props.voucherType+"-givenName-"+ this.props.id}>
                    <Form.Label >{languages[language]['Regis.given']}*</Form.Label>
                    <Form.Control
                        name={this.props.voucherType+"-givenName-"+ this.props.id}
                        value={this.props.data.givenName}
                        required
                        type="text"
                        placeholder="Owner's Middle and Given Name*"
                        onChange={this.handleGivenname}
                        autoComplete="off"
                    />
                    <Form.Control.Feedback>{givenerror}</Form.Control.Feedback>
                </Form.Group>
            </div>
        )
    }
}