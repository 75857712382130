import React,{Component, useState} from "react";
// import ReactPlayer from "react-player";
import { Carousel, Modal, Button, Tabs, Tab,Accordion, Card } from 'react-bootstrap';
import Parser from 'html-react-parser';
import Loading from '../../components/Loading';
import NumberFormat from 'react-number-format';
import ReadMoreReact from 'read-more-react';

export default class MiddlePowerPackFamilyJP extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          data : null,
          loading: false
        };
    }

    render(){
        const {language, languages} = this.props;
        // const colorText = {
        //     color: "#4D4D4D",
        //     fontSize: "1em"
        // }
        // const colortBg = {
        //     backgroundColor : "#FFDE14",
        //     padding:'5px'
        // }

        const colorText = {
            color: "white"
        }

        const boxCSS = {
            backgroundColor : "White",
            borderRadius : "15px",
            margin : 0
        }

        const boxHeaderCSS = {
            borderTopRightRadius : "15px",
            borderTopLeftRadius : "15px",
            backgroundColor:'red', 
            padding:'10px'
        }
        return(
            <div className="main-background">
                <section className="top-middle week-gold" style={{paddingBottom: "10%"}}>
                    <div className="inner-week-gold">
                    <div className="container">
                        <div className="row" style={boxCSS}> 
                                <div className="col-12" style={boxHeaderCSS}>
                                    <h3 style={colorText}>Power Pack</h3>
                                </div>
                                <div className="col-12">
                                    {Parser(languages[language]['PowerpackFamilyJP']['Description'])}<br/><br/>
                                    {Parser(languages[language]['PowerpackFamilyJP']['skyfunner'])}
                                    <br/>
                                </div>
                                <div className="col-12">
                                        <Accordion defaultActiveKey={0}>
                                        {languages[language]['PowerpackFamilyJP']['packages'].map((pk , i) => {
                                            return(
                                                    <Card key={i} >
                                                        <Card.Header>
                                                        <Accordion.Toggle as={Button} eventKey={i}>
                                                            {pk.name}
                                                        </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse show={1} eventKey={i}>
                                                        <Card.Body>
                                                            <div className="row">
                                                            <div className="col-12 col-md-8">
                                                                {Parser(pk.price)}
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                            <a href="#join-us" className="buttonbasic page-scroll">BUY NOW</a>
                                                            </div>
                                                            </div>
                                                            
                                                        </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                            )
                                        })}
                                        </Accordion>
                                        <br/>
                                </div>
                            </div>
                            <br/>
                    </div>
                    </div>
                    
                    <span className="img-hill"><img alt="" src="/images/top_cloud_2.png" className="img-responsive" /></span>
                </section>
            </div>
        )
    }
}

function Termsandconditions(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <a href="#" variant="primary" onClick={handleShow}>
          Terms & Conditions
        </a>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{backgroundColor:"red"}}>
            <Modal.Title>Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {Parser(props.terms)}
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
  }

function Description(props){
    const colorText = {
        color: "#4D4D4D",
        fontSize: "1em"
    }
    let t = props.description.split("<br/>");
    //console.log(t);
    return (
        <>
            <div>
            <br/>
            <span style={colorText}>{Parser(t[0])}</span>
            <ReadMoreReact 
                            min={100}
                            ideal={200}
                            text={t[1]} 
                            readMoreText="...read more"
                            />
            </div>
        </>
    )
}