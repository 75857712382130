import React, { Component, useState } from "react";
// import ReactDOM from 'react-dom';
import {
    Link
} from "react-router-dom";
import { Modal, Form, Col, Button, Accordion, Card } from 'react-bootstrap';
import classNames from 'classnames';
import validator from 'validator';
import _ from 'underscore';
import NumberFormat from 'react-number-format';
import Parser from 'html-react-parser';

// import Form from 'react-validation/build/form';
// import Input from 'react-validation/build/input';


// import Input from "../components/Input";
// import Select from '../components/Select';
//import Button from '../components/Button';


import PaxName from '../PaxName';


export default class  RegisPowerPackFamilyJP extends Component {

    formDefaults = {
        title: { value: 'Mr', isValid: false, message: '' },
        email: { value: '', isValid: true, message: '' },
        familyName: { value: '', isValid: true, message: '' },
        givenName: { value: '', isValid: true, message: '' },
        paxFamilyName: { value: '', isValid: true, message: '' },
        //paxGivenName: { value: '', isValid: true, message: '' },
        phone: { value: '', isValid: true, message: '' },
        referer : {value: '', isValid: false, message: ''},
        termsChecked : { value: false, isValid: false, message: '' },
        PACK4 : { value: [], isValid: true, message: '' },
        PACK6 : { value: [], isValid: true, message: '' },
        PACK12 : { value: [], isValid: true, message: '' },
        PACK24 : { value: [], isValid: true, message: '' },
        PACK50 : { value: [], isValid: true, message: '' },
        PACK100 : { value: [], isValid: true, message: '' }
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.formDefaults,
            genderOptions: ["Mr", "Mrs", "Ms"],
            voucherOptions: ["PACK4","PACK6"],
            type : {
                "PACK4" : {
                    value: 0, 
                    isValid: true, 
                    message: ''
                },
                "PACK6" : {
                    value: 0, 
                    isValid: true, 
                    message: ''
                }
                // "PACK12" : {
                //     value: 0, 
                //     isValid: true, 
                //     message: ''
                // },
                // "PACK24" : {
                //     value: 0, 
                //     isValid: true, 
                //     message: ''
                // }
                // ,
                // "PACK50" : {
                //     value: 0, 
                //     isValid: true, 
                //     message: ''
                // },
                // "PACK100" : {
                //     value: 0, 
                //     isValid: true, 
                //     message: ''
                // }
            },
            errors: false,
            errorMessage: "",
            paxmessage : {
                email : [],
                familyName : [],
                givenName : []
            },
            amtOptions: { },

            purchase:[],
            totalPrice : 0,
            totalVAT : 0,
            discountAmount : 0,
            discountPercent : 0,
            totalAmount: 0,
            serviceFee: 0,
            serviceFeeVAT: 0,
            disable : false
        }

         this.handleCheckBox = this.handleCheckBox.bind(this);
         this.removePaxName = this.removePaxName.bind(this);
         this.onChange = this.onChange.bind(this);
         this.onChangeVoucherNumber = this.onChangeVoucherNumber.bind(this);
    }

    componentWillMount(){
        
        this.setState({
            email: { value: this.props.info.get('email') ? this.props.info.get('email') : '' , isValid: true, message: '' },
            title: { value: this.props.info.get('title') &&  this.props.info.get('title')!== 'not_disclosed' ? this.props.info.get('title') : 'Mr', isValid: false, message: '' },
            familyName: { value: this.props.info.get('lastname') ? this.props.info.get('lastname') : '', isValid: true, message: '' },
            givenName: { value: this.props.info.get('firstname') ? this.props.info.get('firstname') : '', isValid: true, message: '' },
            paxFamilyName: { value: this.props.info.get('lastname') ? this.props.info.get('lastname') : '', isValid: true, message: '' },
            //paxGivenName: { value: this.props.info.get('firstname') ? this.props.info.get('firstname') : '', isValid: true, message: '' },
            phone: { value: this.props.info.get('phone') ? "" + this.props.info.get('phone') : '', isValid: true, message: '' },
            referer: { value: this.props.info.get('referer') ? "" + this.props.info.get('referer') : '', isValid: true, message: '' },
            amtOptions: {
                'PACK4': {
                    'name' : 'PACK 4',
                    'origin': 44000,
                    'vat': 3080,
                    'amount': 47080
                },
                'PACK6': {
                    'name' : 'PACK 6',
                    'origin': 60000,
                    'vat': 4200,
                    'amount': 64200
                }
                // ,
                // 'PACK12': {
                //     'name' : 'PACK 12',
                //     'origin': 23900,
                //     'vat': 1673,
                //     'amount': 25573
                // },
                // 'PACK24': {
                //     'name' : 'PACK 24',
                //     'origin': 41900,
                //     'vat': 2933,
                //     'amount': 44833
                // }
            },
            member : false
        })
    }

    handleCheckBox(e) {
        const newSelection = e.target.value;

        let newSelectionArray;
        if (this.state.vouchers.indexOf(newSelection) > -1) {
            newSelectionArray = this.state.vouchers.filter(
                s => s !== newSelection
            );
        } else {
            newSelectionArray = [...this.state.vouchers, newSelection];
        }

        this.setState(prevState => ({
                vouchers: newSelectionArray
            })
        );
    }


    removeAccents(str) {
        return str.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd').replace(/Đ/g, 'D');
    }

    handlePaxName(id, voucherType, familyName, givenName, email, paxFamilyName){

        let items = [...this.state[voucherType].value];

        let item = {...items[id]};
        item = {
            familyName: this.removeAccents(familyName),
            givenName : this.removeAccents(givenName),
            paxFamilyName: this.removeAccents(paxFamilyName),
            //paxGivenName : this.removeAccents(paxGivenName),
            email: email
        }

        items[id] = item;
        //console.log(id,items[id])
        this.setState(prevState => ({
                [voucherType]: {
                    ...prevState[voucherType],
                    value : items
                }
            })
        );

        //this.setState({[voucherType] : items});
    }

    removePaxName(id, voucherType){

        let items = [...this.state[voucherType].value];
        items.splice(id, 1);

        //console.log(items);

        this.setState(prevState => ({
                [voucherType]: {
                    ...prevState[voucherType],
                    value : items
                }
            }),
            () => this.checkPurchase(voucherType, 0)
        );

    }

    addPaxName = (e) =>{
        e.preventDefault();
        const name = e.target.name;

        let newSelectionArray = this.state[name].value;
        if(newSelectionArray.length < 5){
            newSelectionArray.push({familyName : "", givenName : "", email : ""});

            this.setState(prevState => ({
                    [name]: {
                        ...prevState[name],
                        value: newSelectionArray
                    }
                }),
                () => this.checkPurchase(name, 1)
            )
        }

    }

    checkPurchase(voucherType, ty){
        if(ty === 1){
            this.setState({
                totalVAT: parseFloat(parseFloat(this.state.totalVAT) +  parseFloat(this.state.amtOptions[voucherType]['vat'])).toFixed(2),
                totalAmount : parseFloat(parseFloat(this.state.totalAmount) +  parseFloat(this.state.amtOptions[voucherType]['amount'])).toFixed(2)
            })
        }else {
            this.setState({
                totalVAT: parseFloat(parseFloat(this.state.totalVAT) -  parseFloat(this.state.amtOptions[voucherType]['vat'])).toFixed(2),
                totalAmount : parseFloat(parseFloat(this.state.totalAmount) -  parseFloat(this.state.amtOptions[voucherType]['amount'])).toFixed(2)
            })
        }

    }

    onChange = (e) => {
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: this.removeAccents(e.target.value),
            }
        };

        this.setState(state);

    }

    checkPurchaseVoucher(){
        var that = this;
        let totalVAT = 0;
        let totalAmount = 0;
        let totalPrice = 0;
        _.each(this.state.type, function(voucher, key){
            totalPrice += that.state.amtOptions[key]['origin'] * voucher.value;
            totalVAT += that.state.amtOptions[key]['vat'] * voucher.value;
            totalAmount += that.state.amtOptions[key]['amount'] * voucher.value;
        })

        this.setState({
            totalPrice: parseFloat(totalPrice).toFixed(2),
            totalVAT: parseFloat(totalVAT).toFixed(2),
            //discountAmount : parseFloat(totalAmount * that.state.discountPercent / 100).toFixed(2),
            totalAmount : parseFloat(totalAmount).toFixed(2)
        })

        // this.setState({
        //     totalVAT: parseFloat(parseFloat(this.state.totalVAT) +  parseFloat(this.state.amtOptions[voucherType]['vat']) * ty).toFixed(2),
        //     totalAmount : parseFloat(parseFloat(this.state.totalAmount) +  parseFloat(this.state.amtOptions[voucherType]['amount']) * ty).toFixed(2)
        // })

    }

    onChangeVoucherNumber = (e) => {

        e.preventDefault();
        let name = e.target.name;
        let vouchers = {...this.state.type};
        let voucher = {...vouchers[name]};
        voucher.value = parseInt(e.target.value);
        vouchers[name] = voucher;
        this.setState({type : vouchers},() => this.checkPurchaseVoucher());
    }

    

    onSubmit = (e) => {
        
        e.preventDefault();
        this.resetValidationStates();
        
        if (this.formIsValid()) {
            this.setState({
                disable: true,
            })
            const { title, familyName, givenName, paxFamilyName, email, phone ,referer, type, totalPrice,totalAmount, totalVAT, discountAmount } = this.state;

            let userData = {
                contact:{
                    title: title.value === null ? "Mr" :title.value ,
                    email: email.value.trim(),
                    familyName : familyName.value.trim().toUpperCase(),
                    givenName : givenName.value.trim().toUpperCase(),
                    paxFamilyName : paxFamilyName.value.trim().toUpperCase(),
                    //paxGivenName : paxGivenName.value.trim().toUpperCase(),
                    phone: phone.value.trim().toUpperCase()
                },
                promos :[],
                g : "",
                h : "",
                referer : referer.value.trim().toUpperCase(),
                paymentPrice : parseFloat(parseFloat(totalPrice).toFixed(2)),
                paymentVAT : parseFloat(parseFloat(totalVAT).toFixed(2)),
                paymentDiscount : parseFloat(parseFloat(discountAmount).toFixed(2)),
                paymentTotal: parseFloat(parseFloat(totalAmount).toFixed(2))
            };

            Object.keys(type).forEach((key, i) => {
                if(type[key].value > 0){
                    userData.promos.push({
                        name: key,
                        number : type[key].value
                    })
                }
            })

            if(this.props.info.get('g')){
                userData.g = this.props.info.get('g');
                userData.h = this.props.info.get('h');
            }

            var requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify(userData),
            };
           
            fetch(this.props.apiUrl + "/api/promo/power-pack-family-jp", requestOptions)
                .then(response => response.json())
                .then(result => {

                    const data = result;//JSON.parse(result);
                    this.setState({
                        disable: false,
                    })
                    
                    if(data === ""){
                        this.setState({
                            errors : true,
                            errorMessage: "Oop! Something wrong. Please check your information and try again"
                        })
                        alert("Oop! Something wrong. Please check your information and try again");

                    }else if(!data.status){
                        this.setState({
                            disable: false,
                            errors : true,
                            //errorMessage: data.message
                        })
                        if(data.loyalty){
                            this.setState({
                                member: true
                            })
                        }else{
                            alert(data.message);
                        }
                       
                        
                    }else {
                        window.location.href = data.url;
                    }

                })
                .catch(error => console.log('error', error));

        }
    }

    formIsValid = () => {

        const title = { ...this.state.title };
        const email = { ...this.state.email };
        const familyName = { ...this.state.familyName };
        const givenName = { ...this.state.givenName };
        const paxFamilyName = { ...this.state.paxFamilyName };
        //const paxGivenName = { ...this.state.paxGivenName };
        const phone = { ...this.state.phone };
        const termsChecked = { ...this.state.termsChecked };
        const type = { ...this.state.type };
        // const typeA = { ...this.state.typeA };
        // const typeB = { ...this.state.typeB };
        //const Sky1000 = { ...this.state.Sky1000 };
        var errors = { ...this.state.errors };
        var errorMessage = {...this.state.errorMessage};
        const paxmessage = {...this.state.paxmessage};
        let isGood = true;

        email.message = '';
        familyName.message = '';
        givenName.message = '';
        paxFamilyName.message = '';
        //paxGivenName.message = '';
        phone.message = ''
        paxmessage.email = [];
        paxmessage.familyName = [];
        paxmessage.givenName = [];

        if (!validator.isEmail(email.value)) {
            email.isValid = false;
            email.message = 'Not a valid email address';
            isGood = false;
        }

        if (validator.isEmpty(familyName.value) || !validator.matches(familyName.value.trim(),'^[a-zA-Z]+$')){
            familyName.isValid = false;
            familyName.message = 'The family name is not correct. It accepts only characters.';
            isGood = false;
        }


        if (validator.isEmpty(givenName.value) || !validator.matches(givenName.value.trim(),'^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$')){
            givenName.isValid = false;
            givenName.message = 'The given name is not correct. It accepts only characters and single space.';
            isGood = false;
        }

        if (validator.isEmpty(paxFamilyName.value) || !validator.matches(paxFamilyName.value.trim(),'^[a-zA-Z]+$')){
            paxFamilyName.isValid = false;
            paxFamilyName.message = 'The family name is not correct. It accepts only characters.';
            isGood = false;
        }


        // if (validator.isEmpty(paxGivenName.value) || !validator.matches(paxGivenName.value.trim(),'^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$')){
        //     paxGivenName.isValid = false;
        //     paxGivenName.message = 'The given name is not correct. It accepts only characters and single space.';
        //     isGood = false;
        // }

        if (!validator.isMobilePhone(phone.value,"any", true)){
            phone.isValid = false;
            phone.message = 'Not a valid phone number (eg. +66xxxxxxx)';
            isGood = false;
        }

        if (!termsChecked.value){
            termsChecked.isValid = false;
            isGood = false;
            errors = true;
            alert("You must accept the terms and conditions");
            this.setState({termsChecked:{
                ...this.state.termsChecked,
                message : 'You must accept the terms and conditions'
            }})
            
        }

        var a = true;

        const t = _.find(type, function(num, key){ return num.value > 0; })
        if(t === undefined){
            isGood = false;
            errors = true;
            alert("Please choose at least 1 pormo package");
            errorMessage = "";
        }
        
        if (!isGood) {
            this.setState({
                email,
                title,
                phone,
                familyName,
                givenName,
                paxFamilyName,
                //paxGivenName,
                errors,
                errorMessage,
                paxmessage
            });

            
        }

        return isGood;
    }

    resetValidationStates = () => {
        const state = JSON.parse(JSON.stringify(this.state));

        Object.keys(state).map(key => {
            if (state[key].hasOwnProperty('isValid')) {
                state[key].isValid = true;
                state[key].message = '';
            }

        });

        this.setState(state);
        this.setState({errors: false, errorMessage: ""})


    }

    resetForm = () => {
        this.setState(...this.formDefaults);
    }


     render(){

         const {language, languages} = this.props;

         const {amtOptions, type, title, email, termsChecked ,familyName, givenName, phone, referer, errors, errorMessage, paxmessage, paxFamilyName} = this.state;

         const emailGroupClass = classNames('form-group',
             { 'has-error': !email.isValid }
         );
         const titleGroupClass = classNames('form-group',
             { 'has-error': !title.isValid }
         );
         const famailyNameGroupClass = classNames('form-group',
             { 'has-error': !familyName.isValid }
         );
         const givenNameGroupClass = classNames('form-group',
             { 'has-error': !givenName.isValid }
         );
         const phoneGroupClass = classNames('form-group',
             { 'has-error': !phone.isValid }
         );

         const paxFamilyNameGroupClass = classNames('form-group',
             { 'has-error': !paxFamilyName.isValid }
         );
        //  const paxGivenNameGroupClass = classNames('form-group',
        //      { 'has-error': !paxGivenName.isValid }
        //  );

         return (
            <section className="join-us" id="join-us">
                <div className="contact-us-bx clearfix">
                    <div className="">
                        <Form autoComplete="off" className="form-contact" id="join-us" onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h4 className="title-contact">
                                        {languages[language]['Regis.label']}
                                    </h4>
                                </div>

                                <div className="col-sm-12">
                                    <div className="inner-form-contact">
                                        <strong>{languages[language]['Regis.fill']}:</strong>

                                        <div className="main-form" style={{"marginTop": "15px"}}>
                                            <div className="top-form">
                                                <div className="row" >

                                                    <Form.Group className={titleGroupClass} as={Col} md="2" xs="12" controlId={"title"}>
                                                        <Form.Label>{languages[language]['Regis.title']}</Form.Label>
                                                        <Form.Control as="select"
                                                                      required
                                                                      value={this.state.title.value}
                                                                      onChange={this.onChange}
                                                                      name="title"
                                                        >
                                                            {this.state.genderOptions.map(option => {
                                                                return (
                                                                    <option key={option} value={option} label={option}>
                                                                        {option}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">{email.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className={givenNameGroupClass} as={Col} md="2" xs="12" controlId="givenName">
                                                        <Form.Label>{languages[language]['Regis.given']}</Form.Label>
                                                        <Form.Control
                                                            name="givenName"
                                                            required
                                                            type="text"
                                                            placeholder="Enter your middle and given name"
                                                            value={this.state.givenName.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{givenName.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className={famailyNameGroupClass} as={Col} md="3" controlId="familyName">
                                                        <Form.Label>{languages[language]['Regis.family']}</Form.Label>
                                                        <Form.Control
                                                            name="familyName"
                                                            value={this.state.familyName.value}
                                                            required
                                                            type="text"
                                                            placeholder="Enter your name"
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{familyName.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className={emailGroupClass} as={Col} md="3" xs="12" controlId="email">
                                                        <Form.Label>{languages[language]['Regis.email']}</Form.Label>
                                                        <Form.Control
                                                            name="email"
                                                            required
                                                            type="email"
                                                            placeholder="Enter your email"
                                                            value={this.state.email.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{email.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className={phoneGroupClass} as={Col} md="2" xs="12" controlId="phone">
                                                        <Form.Label>{languages[language]['Regis.phone']}</Form.Label>
                                                        <Form.Control
                                                            name="phone"
                                                            required
                                                            type="text"
                                                            placeholder="eg. +665555551212" 
                                                            //pattern="(\+|(\+[1-9])?[0-9]*)"
                                                            value={this.state.phone.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{phone.message}</Form.Control.Feedback>
                                                    </Form.Group>

                                            </div>
                                            <div className="row" >
                                                    {/* <Form.Group className="" as={Col} md="2" xs="12" controlId="referer">
                                                        <Form.Label>{languages[language]['Regis.referer']}</Form.Label>
                                                        <Form.Control
                                                            name="referer"
                                                            //required
                                                            type="text"
                                                            //placeholder="eg. +665555551212" 
                                                            //pattern="(\+|(\+[1-9])?[0-9]*)"
                                                            value={this.state.referer.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{referer.message}</Form.Control.Feedback>
                                                    </Form.Group> */}

                                                    {/* <Form.Group className={paxGivenNameGroupClass} as={Col} md="4" xs="12" controlId="paxGivenName">
                                                        <Form.Label>{languages[language]['Regis.paxgiven']}</Form.Label>
                                                        <Form.Control
                                                            name="paxGivenName"
                                                            required
                                                            type="text"
                                                            placeholder="Enter Passenger middle and given name"
                                                            value={this.state.paxGivenName.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{paxGivenName.message}</Form.Control.Feedback>
                                                    </Form.Group> */}

                                                    <Form.Group className={paxFamilyNameGroupClass} as={Col} md="4" controlId="paxFamilyName">
                                                        <Form.Label>{languages[language]['Regis.paxfamily']}</Form.Label>
                                                        <Form.Control
                                                            name="paxFamilyName"
                                                            value={this.state.paxFamilyName.value}
                                                            required
                                                            type="text"
                                                            placeholder="Enter Passenger Family name"
                                                            onChange={this.onChange}
                                                        />
                                                        <Form.Control.Feedback>{paxFamilyName.message}</Form.Control.Feedback>
                                                    </Form.Group>
                                            </div>
                                            </div>
                                            <div className="bottom-form">
                                                <div className="row">
                                                {this.state.voucherOptions.map((voucher , i) => {
                                                    const numberVoucher = voucher === 'PACK4' ? [0,1,2,3,4,5] : voucher === 'PACK6' ? [0,1,2,3] : voucher === 'PACK12' ? [0,1,2] : voucher === 'PACK24' ? [0,1] : [];
                                                    return(
                                                        <div key={i} className="col-md-4 col-xs-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <div className="col-12">
                                                                            <span>{amtOptions[voucher].name}</span>
                                                                        </div>
                                                                        {/* <div className="original col-3">
                                                                            <span className="ori">
                                                                                <NumberFormat value={amtOptions[voucher].value} displayType={'text'} thousandSeparator={true} />
                                                                            </span>
                                                                            <small>THB<br/>(*)</small>
                                                                        </div>
                                                                        <div className="discount col-3">
                                                                            <span><NumberFormat value={amtOptions[voucher].origin} displayType={'text'} thousandSeparator={true} /> </span>
                                                                            <small>THB<br/>(*)</small>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row prize">
                                                                            <div className="col-md-6 col-5">
                                                                                {amtOptions[voucher].value ? 
                                                                                    <div className="original">
                                                                                        <span className="ori"><NumberFormat value={amtOptions[voucher].value} displayType={'text'} thousandSeparator={true} /></span>
                                                                                        <small>THB<br/>(*)</small>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                                
                                                                            </div>
                                                                            <div className="sell col-md-6 col-7">
                                                                                <span style={{fontSize: "0.8em"}}> Price <NumberFormat value={amtOptions[voucher].origin} displayType={'text'} thousandSeparator={true} /> THB</span>
                                                                            </div>
                                                                            <div className="col-12">
                                                                            <Form.Group controlId={voucher} className="row">
                                                                                <Form.Label className="col-6">Number</Form.Label>
                                                                                <Form.Control as="select"
                                                                                            required
                                                                                            value={type[voucher].value}
                                                                                            onChange={this.onChangeVoucherNumber}
                                                                                            name={voucher}
                                                                                            className="col-6"
                                                                                >
                                                                                    {numberVoucher.map(option => {
                                                                                        return (
                                                                                            <option key={option} value={option} label={option}>
                                                                                                {option}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </Form.Control>
                                                                                <Form.Control.Feedback type="invalid">{email.message}</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                                {/* <span>Value 200 THB</span> */}
                                                                                {/* <div className="discount">
                                                                                    <span>99</span>
                                                                                    <small>THB<br/>(*)</small>
                                                                                </div> */}
                                                                            </div>
                                                                            <div className="sell col-md-6 col-4">
                                                                                {/* <span><NumberFormat value={room.selling_price} displayType={'text'} thousandSeparator={true} /> THB/night</span> */}
                                                                                {/* <div className="original">
                                                                                    <span className="ori">200</span>
                                                                                    <small>THB<br/>(*)</small>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="valid-feedback">{type[room.code].message}</div> */}
                                                                        {/* {type[room.code].value.map((option, index)  => {
                                                                                return (
                                                                                    <PaxName data={option} errormessage={{...paxmessage}} contactName={{familyName,givenName, email}} language={language} languages={languages}  key={index} id={index} voucherType={room.code} handlePaxName={this.handlePaxName.bind(this)} handleDelPaxName={this.removePaxName.bind(this)} />
                                                                                );
                                                                            }
                                                                        )} */}


                                                                    </div>
                                    
                                                                </div>
                                                            </div>
                                                    )
                                                })}
                                                </div>

                                                <div className="row">

                                                <div className="col-md-6 col-xs-12">
                                                        <div className="container purchase">
                                                            <div className="row">
                                                                <div className="col"><h4>{languages[language]['Regis.purchase']}</h4></div>
                                                            </div>
                                                            {
                                                            Object.keys(type).map((key , i) => {
                                                                return (
                                                                    <div key={i} >
                                                                    {type[key].value === 0 ? 
                                                                    <div className="mb-2 row" >
                                                                        <div className="col">{amtOptions[key].name} X 0</div>
                                                                        <div className="text-right col">0.00 THB</div>
                                                                    </div>
                                                                    :
                                                                    <div className="mb-2 row">
                                                                            <div className="col">{amtOptions[key].name} X {type[key].value}</div>
                                                                            <div className="text-right col">
                                                                                <NumberFormat value={parseFloat(amtOptions[key].origin * type[key].value).toFixed(2)} displayType={'text'} thousandSeparator={true} /> THB</div>
                                                                        </div>
                                                                    }
                                                                    </div>
                                                                    
                                                                )
                                                            })}

                                                            <div className="mb-2 row">
                                                                <div className="col">{languages[language]['Regis.vat']}</div>
                                                                <div className="text-right col"><NumberFormat value={parseFloat(this.state.totalVAT).toFixed(2)} displayType={'text'} thousandSeparator={true}   /> THB</div>
                                                            </div>

                                                            {/* <div className="totalRow row">
                                                                <div className="col">Discount</div>
                                                                <div className="text-right col"><NumberFormat value={parseFloat(this.state.discountAmount).toFixed(2)} displayType={'text'} thousandSeparator={true}  /> THB</div>
                                                            </div> */}

                                                            <div className="totalRow row">
                                                                <div className="col">{languages[language]['Regis.total']}</div>
                                                                <div className="text-right col"><NumberFormat value={parseFloat(this.state.totalAmount).toFixed(2)} displayType={'text'} thousandSeparator={true}  /> THB</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                                    <div className="form-group has-error form-group">
                                                                            <div className="form-check">
                                                                                <input required type="checkbox" className="form-check-input" onClick={e => this.setState({termsChecked : { ...this.state.termsChecked,value : e.target.checked}})}/>
                                                                                <label title="" className="form-check-label">
                                                                                <Termsandconditions terms={languages[language]['PowerpackFamilyJP']['terms']} lang={languages[language]['Regis.accept']}/>
                                                                                </label>
                                                                            </div>
                                                                            <div className="valid-feedback">{termsChecked.message}</div>
                                                                    </div>
                                                                    
                                                                </div>
                                                        
                                                        <button className="button next" type="submit" disabled={this.state.disable}>Submit</button>
                                                    </div>

                                                </div>
                                            </div>

                                            
                                                
{/* terms */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <p>{errors === true ? <span>{errorMessage}</span>: ""}</p>
                     </div>
                     <hr/>
                     <div className="row" style={{textAlign:"center"}}>
                         <div className="col-12">
                         <Termsandconditions terms={languages[language]['PowerpackFamilyJP']['terms']} lang="Terms and Conditions"/> | <FAQ faq={languages[language]['PowerpackFamilyJP']['faq']} lang="FAQ"/> | <Termsandconditions terms={languages[language]['PowerpackFamilyJP']['howtouse']} lang="How to use"/>
                         </div>
                     
                         {/* <div className="col-6" style={{
                             textAlign: "right",
                             borderRight: "1px solid gray"
                         }}>
                            <Termsandconditions terms={languages[language]['Powerpack']['terms']} lang="Terms and Conditions"/>
                         </div>
                         <div className="col-6">
                            <FAQ faq={languages[language]['Powerpack']['faq']} lang="FAQ"/>
                         </div> */}
                     </div>
                     <Modal show={this.state.member} closeButton >
                       
                        <Modal.Body closeButton>{Parser(languages[language]['Powerpack']['member'])}</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({member: false})}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>
        )
     }

}



function FAQ(props){
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <a href="#" variant="primary" onClick={handleShow}>
          {props.lang}
        </a>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{backgroundColor:"red"}}>
            <Modal.Title>{props.lang}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Accordion defaultActiveKey={0}>
            {props.faq.map((pk , i) => {
                return(
                    
                    <Card key={i}>
                        <Accordion.Toggle as={Card.Header} eventKey={i}>
                        <span style={{color:"white"}}>{pk.question}</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i}>
                        <Card.Body>{Parser(pk.answer)}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
            })}
            </Accordion>
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
}

function Termsandconditions(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <a href="#" variant="primary" onClick={handleShow}>
          {props.lang}
        </a>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{backgroundColor:"red"}}>
            <Modal.Title>{props.lang}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {Parser(props.terms)}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
}
