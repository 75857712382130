import React,{Component} from "react";

import {
    Link
} from "react-router-dom";
//import logo from '/'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

// const styles = {
//
//     display: "none",
//     height: "auto",
//     paddingTop: "0px",
//     marginTop: "0px",
//     paddingBottom: "0px",
//     marginBottom: "0px"
// };

export default class Header extends Component  {
    constructor(props) {
        super(props);

        this.changeLanguage = this.changeLanguage.bind(this);
    }
    //var language = 'English';
    //const callLang = () => i18n.on("load");
    //const { t, i18n } = useTranslation()

    changeLanguage(e){
        //e.preventDefault();
        this.props.checkLang(e)
        //console.log(event)
        // if(event.target.id === 'th'){
        //     language = 'Thai';
        // }else{
        //     language = 'English';
        // }
        //i18n.changeLanguage(event.target.id)
    }
    render() {
        const {language, languages, apiUrl} = this.props;

        return(
            <header className="bg-header">
                <Navbar className="navbar-default navbar-dark bg-red sticky-top" expand="lg">
                    <Navbar.Brand>
                        <a href={apiUrl}><img
                            src="https://s3.ap-southeast-1.amazonaws.com/skycms.skyfun.biz/skyfun_logo_1447f3d028.png"
                            width="300px"
                            className="d-inline-block align-top"
                            alt="VietjetAir"
                        /></a>
                        
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="">
                        <Nav className="mr-auto">
                            <a  className="nav-link" href={apiUrl + "/searchreservation"}>{this.props.language === 'en'?"My Bookings":"การจองของฉัน "} </a>
                            <NavDropdown title="Shopping" className="btn-yellowGradient" id="terms">
                                
                                <NavDropdown.Item href="/power-pack-family-dom">Family Power Pack Domestic</NavDropdown.Item>
                                <NavDropdown.Item href="/power-pack-family-jp">Family Power Pack Japan</NavDropdown.Item>
                                <NavDropdown.Item href="/covidtest">Covid Test Voucher</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/insurance"}>SkyFUN Insurance</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" href="https://www.trip.com/sale/w/2700/vietjetair.html?locale=en_xx&allianceid=3223166&sid=7260158">Hotel Voucher</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/voucher/shopping/travel" }>Travel Voucher</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title={this.props.language === 'en'?"Travel Info":"ข้อมูลการเดินทาง"} id="languages">
                                <NavDropdown.Item href={apiUrl + "/promotion" }>{this.props.language === 'en'?"Promotion":"โปรโมชัน"}</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/travelinfo/identification" }>{this.props.language === 'en'?"Travel Document":"เอกสารที่ใช้ในการเดินทาง"}</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/airportandterminal" }>{this.props.language === 'en'?"Airports and Terminal":"สนามบินและอาคารผู้โดยสาร"}</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/travelinfo/taxinvoice" }>{this.props.language === 'en'?"Tax Invoice":"ใบกำกับภาษี"}</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/travelinfo/seat" }>{this.props.language === 'en'?"Seat selection":"การเลือกที่นั่ง"}</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/travelinfo/specialassistance" }>{this.props.language === 'en'?"Physically Challenged Passenger":"ผู้โดยสารที่ต้องการความช่วยเหลือพิเศษ"}</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/foodanddrink" }>{this.props.language === 'en'?"Products Foods and Beverage":"สินค้า อาหารเเละเครื่องดื่ม"}</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/travelinfo/baggage" }>{this.props.language === 'en'?"Baggage service":"จัดส่งสัมภาระ"}</NavDropdown.Item>
                                <NavDropdown.Item href={apiUrl + "/skyboss" }>SKY BOSS</NavDropdown.Item>
                                
                            </NavDropdown>
                            
                            <a  className="nav-link" href="https://vzcheckin.vietjetair.com/">{this.props.language === 'en'?"Web Check-in":"เช็คอินออนไลน์"} </a>
                            
                        </Nav>

                        <Nav className="ml-auto">
                            <NavDropdown title={this.props.language === 'en'?"English":"ภาษาไทย"} id="languages">
                                <NavDropdown.Item onClick={() => this.changeLanguage('en')} id="en">English</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.changeLanguage('th')} id="th">ภาษาไทย</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>

                        {/*<Form inline>*/}
                        {/*<FormControl type="text" placeholder="Search" className="mr-sm-2" />*/}
                        {/*<Button variant="outline-success">Search</Button>*/}
                        {/*</Form>*/}
                    </Navbar.Collapse>

                </Navbar>
            </header>
        )

    }


}

