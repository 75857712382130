import React,{Component, useState} from "react";
import { Carousel, Modal, Button, Tabs, Tab } from 'react-bootstrap';
import Parser from 'html-react-parser';
import Loading from '../../components/Loading';
import NumberFormat from 'react-number-format';
import ReadMoreReact from 'read-more-react';

export default class MiddleVoucher extends Component {

    constructor(props) {
        super(props);
  
        this.state = {
          data : null,
          loading: true
        };
    }

    UNSAFE_componentWillMount() {
        this.setState({ data : this.props.data, loading:false })
    }

    render(){
        const {language, languages} = this.props;
        const colorText = {
            color: "white"
        }

        const boxCSS = {
            backgroundColor : "White",
            borderRadius : "15px",
            margin : 0
        }

        const boxHeaderCSS = {
            borderTopRightRadius : "15px",
            borderTopLeftRadius : "15px",
            backgroundColor:'red', 
            padding:'10px'
        }

        return (
            <div className="main-background">
            <section className="top-middle week-gold" style={{paddingBottom: "10%"}}>
                <div className="inner-week-gold">
                {!this.state.loading ? (
                    <div className="container">
                        {this.state.data.map(hotel => {
                            return (
                                <div key={hotel['id']}>
                                    <div className="row" style={boxCSS}> 
                                        <div className="col-12" style={boxHeaderCSS}>
                                            <h3 style={colorText}>{language === 'th' ? hotel['name_th']: hotel['name']}</h3>
                                        </div>
                                        <div className="col-md-4 col-12">
                                        <Carousel>
                                            {hotel['gallery'].map( (gallery, i) => {
                                                return (
                                                    <Carousel.Item key={i}>
                                                        <img
                                                        className="d-block w-100"
                                                        src={gallery.url}
                                                        alt="First slide"
                                                        />
                                                        <Carousel.Caption>
                                                        <h3></h3>
                                                        <p></p>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                );
                                            })}
                                            
                                            </Carousel>
                                        </div>
                                        <div className="col-md-8 col-12">
                                            <div style={{paddingTop:"10px"}}>
                                                <h3>{language === 'th' ? hotel['name_th']: hotel['name']}</h3>
                                                <p>Tel: {hotel['tel']}</p>
                                                <p>Email: {hotel['email']}</p>
                                            </div>
                                            <Tabs defaultActiveKey="description" id="uncontrolled-tab-example">
                                                <Tab eventKey="description" title="Description">
                                                    <Description description={language === 'th' ? hotel['description_th']: hotel['description']} />
                                                </Tab>
                                                <Tab eventKey="location" title="Location">
                                                    <iframe title={hotel['name']} src={hotel['location']} width="100%" height="450" frameBorder="0" style={{border:'0'}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                                                </Tab>
                                            </Tabs>
                                            
                                        </div>
                                    </div>
                                    <br/>
                                    {hotel['product_type'].map(room => {
                                        return(
                                            <div key={room['id']}>
                                                <div  className="row" style={boxCSS}> 
                                                    <div className="col-12" style={boxHeaderCSS}>
                                                        <h4 style={colorText}>{language === 'th' ? room['name_th']: room['name']}</h4>
                                                    </div>
                                                    <div className="row" style={{padding:"5px",marginTop:"10px", width:"100%"}}>
                                                        <div className="col-md-5 col-6">
                                                            <p>{language === 'th' ? room['validity_th']: room['validity']}</p>
                                                            {/* <p>Black out period</p> */}
                                                            <p><Termsandconditions terms={language === 'th' ? room['terms_th']: room['terms']}/></p>
                                                        </div>
                                                        <div className="col-md-4 col-6">
                                                            <p><span style={{textDecoration: "line-through"}}><NumberFormat value={room['original_price']} displayType={'text'} thousandSeparator={true} /> THB {hotel['type'] === 'hotel' ? '/ 1 Night / 1 Room' : '' }</span></p>
                                                            <p><strong style={{color:"red"}}><NumberFormat value={room['selling_price']} displayType={'text'} thousandSeparator={true} /> THB {hotel['type'] === 'hotel' ? '/ 1 Night / 1 Room' : '' }</strong></p>
                                                        </div>
                                                        <div className="col-md-3 col-12">
                                                            <a href="#join-us" className="buttonbasic page-scroll">BUY NOW</a>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <br/>
                                            </div>
                                            
                                            
                                        )
                                        
                                    })}
                                    
                                    
                                </div>
                            );
                        })}
                        
                        
                    </div>
                ) : <Loading />
                }
                </div>
                
                <span className="img-hill"><img alt="" src="/images/top_cloud_2.png" className="img-responsive" /></span>
            </section>
            </div>
        )
    }
}

function Termsandconditions(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <a href="#" variant="primary" onClick={handleShow}>
          Terms & Conditions
        </a>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{backgroundColor:"red"}}>
            <Modal.Title>Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {Parser(props.terms)}
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
  }

function Description(props){
    const colorText = {
        color: "#4D4D4D",
        fontSize: "1em"
    }
    let t = props.description.split("<br/>");
    return (
        <>
            <div>
            <br/>
            <strong style={colorText}>{Parser(t[0])}</strong>
            <ReadMoreReact 
                            min={100}
                            ideal={200}
                            text={t[1]} 
                            readMoreText="...read more"
                            />
            </div>
        </>
    )
}
