import React, { Component, useParams } from "react";
import Loading from '../../components/Loading';
import MiddleVoucher from './middle';
import RegisVoucher from './regis';
import { matchPath } from "react-router-dom";

const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
      path: `/voucher/:voucherId`,
    });
    return (matchProfile && matchProfile.params) || {};
};

export default class Voucher extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
          data : [],
          loading: true
        };
    }

    

    UNSAFE_componentWillMount() {
        const { pathname } = this.props.location;
        //const { pathname : prevPathname } = prevProps.location;
        const currentParams = getParams(pathname);
        //const prevParams = getParams(prevPathname);

        if(currentParams.voucherId ){
            //&& currentParams.voucherId !== prevParams.voucherId){
            fetch(this.props.apiUrl + '/api/voucher/shop/' + currentParams.voucherId)
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ data : responseJson, loading:false })
            })
            .catch((error) => {
              console.error(error);
            });
        }

        
    }

    render() {
        
        const info = new URLSearchParams(localStorage.getItem('info'));
        return (
            <div className="clearfix wrap-home" data-seuence="500">
                <div className="fallingLeaves"></div>
                
                {!this.state.loading ? (
                    <div>
                        <section className="top-section text-center">
                            <div >
                                <img alt="..." src={ "/images/" + this.state.data[0]['type'] + "/banner.jpg"}  className="d-none d-sm-block w-100" style={{width:'100%'}} />
                                <img alt="..." src={ "/images/" + this.state.data[0]['type'] + "/banner-mobile.jpg"} className="d-block d-sm-none w-100" style={{width:'100%'}} />
                            </div>
                        </section>
                        <MiddleVoucher {...this.props} data={this.state.data} />
                        <RegisVoucher info={info} {...this.props} data={this.state.data[0]} />
                    </div>
                ): <Loading />
                }
                
            </div>
        )
    }
}

